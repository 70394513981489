
import { websiteUrl, websiteImageBaseUrl, websiteName } from './constants'
import { wfaPrices } from '@/lib/app-pricing/wfa-prices'


const today = new Date();
const currentYear = today.getFullYear()

// GENERIC HELPERS 
export const rgx = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,  // Simple: /^\S+@\S+$/i
  username: /^[a-zA-Z0-9]+$/i, 
  unicodeWord: /^\p{L}*$/i,  // matches any kind of letter from any language. no numbers, symbols etc. Does not wrk here!
  excludeSymbols: /^[^<>~%# :;,.$%?!”’^&)(*+-\/=@\]\[\\_`}{|]+$/i, 
  firstName: /^[^<>~%# :;,.$%?!”’^&)(*+-\/=@\]\[\\_`}{|]+$/i, 
  lastName: /^[^<>~%# :;,.$%?!”’^&)(*+-\/=@\]\[\\_`}{|]+$/i, 
  url: /^(https?|http):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i
}

export const levenshteinDistance = (str1 = '', str2 = '') => {
  // The Levenshtein distance is a string metric for measuring the difference between two sequences. 
  // It is the minimum number of single-character edits required to change one word into the other.
  const track = Array(str2.length + 1).fill(null).map(() =>
  Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
     track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
     track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
     for (let i = 1; i <= str1.length; i += 1) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
           track[j][i - 1] + 1, // deletion
           track[j - 1][i] + 1, // insertion
           track[j - 1][i - 1] + indicator, // substitution
        );
     }
  }
  return track[str2.length][str1.length];
}

export const cantorPairing = (a,b) => (a+b)*(a+b+1)/2 + b  // using to generate key based on 2 IDs


export const isResponseEmpty = (r) => {
  // Checking API responses if it's empty or not. 
  if (!r) return true  // covers empty string for example
  if (JSON.stringify(r) === '{}') return true
  if (JSON.stringify(r).toLowerCase() === 'not found') return true  // In strapi v3 API was returning not found   
  // if (r && (Object.keys(r).length === 0) && (Object.getPrototypeOf(obj) === Object.prototype)) return true  // covers {}  
}

export function reactToText(node) {
  try {
    if (typeof node === 'string' || typeof node === 'number' || typeof node === 'boolean') {
        return node.toString();
    }
    if (!node) {
        return '';
    }
    if (Array.isArray(node)) {
        return node.map(reactToText).join('');
    }
    // Because ReactNode includes {} in its union we need to jump through a few hoops.
    const props = node.props ? node.props : {};
    if (!props || !props.children) {
        return '';
    }
    return reactToText(props.children);
  } catch {}; 
  return false
  // source: https://github.com/lhansford/react-to-text
}

export const generateRandomString = (n) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'; // 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for ( let i = 0; i < n; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const commaSeparatedListPretty = (items=[], word='and') => {
  // items ['one', 'two', 'three'] => 'one, two and thre'
  if (!Array.isArray(items)) return null
  if (items?.length === 0) {
    return null 
  } else if (items?.length === 1) {
    return items?.[0]
  } else if (items?.length>1) {
    return `${items?.slice(0,-1)?.join(', ')} ${word} ${items[items?.length-1]}`
  }  
}

export const filterObjectByKeys = (object={}, keys=[]) => {
  if (keys?.length === 0) return object
  return Object.keys(object)
    .filter((key) => keys.includes(key))
    .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: object[key]
        });
    }, {});
}


// Native
export function pick(input, keys) {
  // https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
  // var input = { 'a': 1, 'b': '2', 'c': 3 };
  // var result = pick(input, ['a', 'c']);
  // output: {a: 1, c: 3}
  

  return keys.reduce((obj, key) => {
     if (input && input.hasOwnProperty(key)) {
        obj[key] = input[key];
     }
     return obj;
   }, {});
}


// Deep - tried to do , did not finish 
// export const pick = (input, paths) => {
//   // pick(x, ["page1.header", "page1.footer"])
//   // var x = { a: { b: 'b', c: { d: 'd'}, h: 'h' }, a2: { test: 'test'} }
//   // pick(x, ['a.c', 'a.h', 'a2'])

//   return paths.reduce((obj, path) => {    
//     const keysList = path.split(".")
//     const currentObj = pickOne(input, keysList)
//     return merge(obj,currentObj)
//   }, {});
// }


// export const pickOne = (input={}, keysList=[]) => {  
//   // Example: 
//   // const x = { 
//   //   page1: {
//   //     header: { text1: "text1",  text2: "text2" }, 
//   //     footer: { foot1: "foot1", foot2: "foot2" },
//   //     body: { body1: "body1", body2: "body2" },
//   //   }, 
//   //   page2: {
//   //     header: { text1: "text_two1",  text2: "text_two2" }, 
//   //     footer: { foot1: "foot_two1", foot2: "foot_two2" },
//   //     body: { body1: "body_two1", body2: "body_two2" },
//   //   }
//   // }
//   // input = { a: { b: 'b', c: { d: 'd'}} }
//   // pickOne(x, ['a']) -> { b: 'b', c: { d: 'd'}}
//   // pickOne(x, ['a', 'c']) -> { d: 'd'}
//   // pickOne(x, ['a', 'b']) - ERROR
//   // pickOne(x, ['a', 'c', 'd']) - ERROR
//   if (!keysList || (keysList?.length === 0)) return null
//   if (keysList.length === 1) {
//     return input[keysList[0]]
//   } else {
//     const [currentKey, ...restKeys] = keysList
//     const nextItem = pickOne(input[currentKey], restKeys)
//     if (typeof(nextItem) === 'object') return { ...nextItem}
//     throw 'must not get that deep'  // the deepest level should be 
//   }
// }


// const merge = (target, source) => {
//   // merge({a: "a", y: { first: "first1"}}, { x: "ex", y: { test: "test1", best: "best1"}})
//   // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
//   for (const key of Object.keys(source)) {
//     if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
//   }

//   // Join `target` and modified `source`
//   Object.assign(target || {}, source)
//   return target
// }



// WEBSITE_RELATED HELPERS 
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const imageSizeByClass = (className) => {
  // returns size in px based on class. analyzes h-xxx ckassname. 
  const sizes =       [24, 32, 40, 48, 56, 64, 96, 128, 144, 192] // px
  const classSizes =  [6,  8,  10, 12, 14, 16, 24, 32,  36,  48]  // as class name
  const defaultSize = 48 // 48 is 192x192
  let classSize = defaultSize
  try {
    // className // 'h-16 w-16 md:h-20 md:w-20 rounded-md'
    const classNamesList = className?.split(' ')?.map(c => c?.split(':'))?.flat()  // ['h-16', 'w-16', 'md', 'h-20', 'md', 'w-20', 'rounded-md'] // eliminate md:h-12 etc
    const heightsList = classNamesList?.filter(x => x?.includes(`h-`))?.map(x => x?.replace('h-', '')) // ['16', '20']
    const classHeight = Math.max(...heightsList) // 24, or 20
    if (classSizes?.includes(classHeight)) {
      classSize = classHeight || defaultSize
    }
  } catch (error) {}

  const sizeIndex = classSizes.indexOf(classSize); 
  const size = sizes[sizeIndex]
  return size
  // Other staff 
  // const respBreakpoints = ['sm', 'md', 'lg', 'xl', '2xl'] // responsive prefixes
  // const classNameSanitized = className?.split(' ')?.filter(x => !respBreakpoints.some(y => x?.includes(y)))?.join(' ') // remove all responsive options
}

export const appSocialNetworksList = ['twitter', 'linkedin', 'facebook', 'instagram', 'youtube', 'github', 'stackoverflow', 'wikipedia', 'reddit']; 


export const getSocialLink = (social, slug) => {
  // social: facebook, slug: 38492734 or stackreaction etc
  let url = false
  if (social === 'twitter') {
    url = `https://twitter.com/${slug}`
  } else if (social === 'linkedin') {
    url = `https://www.linkedin.com/${slug}`
  } else if (social === 'facebook') {
    url = `https://facebook.com/${slug}`
  } else if (social === 'instagram') {
    url = `https://instagram.com/${slug}`
  } else if (social === 'github') {
    url = `https://github.com/${slug}`
  }
  // TODO 

  return url
}

export const getVideoImage = (url) => {  
  let imageURL = false
  
  if (url?.includes('https://www.youtube.com/watch?v=')) {
    let videoId = url.split('v=')[1]
    const ampersandPosition = videoId.indexOf('&')
    if (ampersandPosition != -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    imageURL = videoId ? `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg` : false
  }

  return imageURL
}


export const getAppPrimaryCategory = (app_categories) => {
  // returns primary category or the first one   
  return app_categories?.sort((a, b) => b.is_primary-a.is_primary)?.[0]?.category 
}


export const pricing = () => {
// TODO 
  x = [
    { 
      slug: 'zapier',
      item: 'task',
      item_description: 'A task is counted every time a Zap successfully moves data or takes action for you. \
                          For example, if your Zap has an action to create new Google Contacts, \
                          each contact that is created will use one task.'

    }, 
    { 
      slug: 'integromat',
      item: 'task',
      item_description: 'A task is counted every time a Zap successfully moves data or takes action for you. \
                          For example, if your Zap has an action to create new Google Contacts, \
                          each contact that is created will use one task.'
    }, 
    { 
      slug: 'automateio',
      item: 'action',
      item_description: 'An Action is something you want to perform in one of your apps. \
                          For example, creating a new contact in your CRM or sending an email. \
                          In a Bot, everything defined after the Trigger Event is an action.'
    }        
  ]

} 


export function getWfaPricingPerVolume(sourceSlug, volume=100, premiumApps=0, stepsCount=2) {
  // wfaPrices
  let pricingLines = wfaPrices?.filter(x => x.sourceslug === sourceSlug)
  pricingLines = pricingLines?.sort((a, b) => {
    return parseInt(a.volume) - parseInt(b.volume)
  })

  // Filter according to PremiumApps
  if (premiumApps) {
    pricingLines = pricingLines.filter(pricingLine => {
      if (pricingLine.premiumapps === '∞') return true 
      if (parseInt(premiumApps) <= pricingLine.premiumapps) return true 
      return false
    })
  }

  // Filter according to StepsCount
  if (stepsCount) {
    pricingLines = pricingLines.filter(pricingLine => {
      if (pricingLine.maxsteps === '∞') return true 
      if (parseInt(stepsCount) <= pricingLine.maxsteps) return true 
      return false
    })    
  }

  const priceCalcType = pricingLines?.[0]?.pricecalctype
  let accountableVolume = 0 
  if (priceCalcType === 'per_executions') {
    accountableVolume = 1*volume
  } else if (priceCalcType === 'per_actions_and_triggers') {
    accountableVolume = stepsCount*volume    
  } else if (priceCalcType === 'per_actions') {
    accountableVolume = (stepsCount - 1)*volume
  }

  let selectedPl
  for (const pricingLine of pricingLines) {
    if (accountableVolume <= pricingLine.volume) {
      selectedPl = pricingLine
      break;
    } else {
      continue;
    }
  }

  if (!selectedPl) return null

  return {
    source_slug: sourceSlug, 
    tier: selectedPl.tier, 
    max_volume: selectedPl.volume, 
    price_monthly: selectedPl.pricemonthly,
    price_yearly: selectedPl.priceyearly
  }
  

}


// IMAGE URL PER DIFFERENT CONTENT TYPES
export const websiteLogoUrl = (size=1024) => `${websiteUrl}/img/logo/logo-${size}px.png`  // [16, 24, 32, 40, 48, 56, 64, 72, 76, 96, 114, 120, 128, 144, 152, 180, 192, 1024, 1200]
export const appLogoUrl = (slug, size=192) => `${websiteImageBaseUrl}/apps/logos/${slug}_${size}.png`  // [24, 32, 40, 48, 56, 64, 96, 128, 144, 192]

export const appKeyImageUrl = (slug) => `${websiteImageBaseUrl}/apps/key/${slug}.png`  // OG Generated Image, 600x600
export const extAppActionSquareImageUrl = (id) => `${websiteImageBaseUrl}/external-app-actions/square/${id}.png`  // OG Generated Image, 600x600
export const sourceConnectionSquareImageUrl = (sourceSlug, appSlug) => `${websiteImageBaseUrl}/source-connections/square/${sourceSlug}--${appSlug}.png`  // OG Generated Image, 600x600

export const extAutomationImageUrl = (id) => `${websiteImageBaseUrl}/external-automations/profiles/${id}.png`  // OG Generated Image, 1200x600
export const extAutomationCustomizeDataflow =`${websiteImageBaseUrl}/external-automations/static/customize-dataflow.png`  // OG Generated Image, 600x600
export const extAutomationTestWorkflow =`${websiteImageBaseUrl}/external-automations/static/test-workflow.png`  // OG Generated Image, 600x600


export const automationURL = ({ extAutomation={}, full=false }) => {
  // full=false - returns path with /
  // activecampaign/automations/53785-bla-bla-slug-using-integromat
  // if use 'full' mode, then it uses full url with website (used in HowTo)
  if (!extAutomation?.id) return ''
  const { external_automation_actions: extAutomationActions=[] } = extAutomation

  // Get Trigger App from extAutomation then from automationActions. 
  let triggerApp = extAutomation?.trigger_app
  if (!triggerApp?.id) {
    triggerApp = extAutomationActions?.find(x => x?.type === 'trigger')?.app  
  }

  if (!triggerApp?.id) return ''

  const sourceSlug = extAutomation?.source_slug || extAutomation?.source?.slug
  const url = `${full ? websiteUrl : ''}/${triggerApp?.slug}/automations/${extAutomation?.id}-${extAutomation?.slug}-using-${sourceSlug}`
  return url
}

export const automationImageTitle = (extAutomation={}) => {
  const { external_automation_actions: extAutomationActions=[] } = extAutomation
  const apps = externalAutomationUniqApps(extAutomationActions)  // unique apps extracted from extAutomationActions
  const title = `Workflow diagram: ${apps?.map(app => app?.name)?.join(' + ')} integration`  
  return title
}

export const externalAutomationUniqApps = (extAutomationActions=[]) => {
  if (extAutomationActions?.length === 0) return []   
  const extAutomationActionsApps = extAutomationActions?.map(item => item?.app)?.filter(item => item?.id)    
  const uniqAppIDs = [...new Set(extAutomationActionsApps?.map(x => x?.id))]
  if (uniqAppIDs?.length === 0) return [] 
  const apps = uniqAppIDs?.map(x => extAutomationActionsApps?.find(a => a?.id === x))
  return apps
}

export const externalAutomationUniqPrimaryCategories = (extAutomation={}) => {
  const { external_automation_actions: extAutomationActions=[] } = extAutomation
  const apps = externalAutomationUniqApps(extAutomationActions)
  if (apps?.length === 0) return [] 
  const categoriesAll = apps?.map(app => app?.primary_category)
  const uniqCategoryIDs = [...new Set(categoriesAll?.map(x => x?.id))]
  if (uniqCategoryIDs?.length === 0) return [] 
  const categories = uniqCategoryIDs?.map(x => categoriesAll?.find(a => a?.id === x))  
  return categories
}

export const externalAutomationCommonSources = (extAutomation={}) => {
  // gets common automation sources from apps.
  const { external_automation_actions: extAutomationActions=[] } = extAutomation
  const apps = externalAutomationUniqApps(extAutomationActions)
  if (apps?.length === 0) return [] 
  const sourcesAll = apps?.map(app => app?.external_apps?.map(x => x.source))?.flat()
  const uniqSourceIDs = [...new Set(sourcesAll?.map(x => x?.id))]
  if (uniqSourceIDs?.length === 0) return [] 
  const sourcesUniq = uniqSourceIDs?.map(x => sourcesAll?.find(a => a?.id === x))  // all uniq sources from all apps 

  const sources = sourcesUniq?.map(source => {
    // check if this source is in all apps 
    const appsWithCurrentSource = apps?.filter(app => app?.external_apps?.filter(x => x.source?.id === source?.id)?.length>0)
    return appsWithCurrentSource?.length === apps?.length ? source : null
  })?.filter(x => x)

  return sources
}

export const externalAutomationDescription = (extAutomation) => {
  // used both in OG and Schena JSONLD
  const { external_automation_actions: extAutomationActions=[] } = extAutomation
  const apps = externalAutomationUniqApps(extAutomationActions)  // unique apps extracted from extAutomationActions
  const appNamesList = commaSeparatedListPretty(apps?.map(x => x?.name))  
  const source = extAutomation?.source
  const triggerApp = extAutomationActions?.find(x => x?.type === 'trigger')?.app
  const actionApps = extAutomationActions?.filter(x => x?.type != 'trigger')?.map(x => x?.app)
  const actionAppsNamesList = commaSeparatedListPretty(actionApps?.map(x => x?.name))  

  const descr1 = `How to set up an automatic workflow that allows to ${extAutomation?.name}.` 
  const descr2 = `Use ${source?.name} to link ${triggerApp?.name} to ${actionAppsNamesList}.`
  const description = `${descr1} ${descr2}`

  // let description = ''
  // if (actionApps?.length === 1) {
  //   const actionAppsNames = actionApps?.[0]?.name
  //   description = `You can transfer the data from ${triggerApp?.name} to ${actionAppsNames} using this integration template.`
  // } else if (actionApps?.length === 2) {
  //   const actionAppsNames = `${actionApps?.[0]?.name} and ${actionApps?.[1]?.name}`
  //   description = `You can connect ${triggerApp?.name} to ${actionAppsNames} using this integration template.`
  // } else if (actionApps?.length > 2) {
  //   const actionAppsNames = `${actionApps?.slice(0, actionApps?.length)?.map(x => x?.name)?.join(', ')} \
  //                     and ${actionApps?.[actionApps?.length-1]?.name}`
  //   description = `You can connect ${triggerApp?.name} to ${actionAppsNames} using this integration template.`
  // }
  return description
}

export const appWebsiteLink = (app) => {  
  // 1. Return url_ref if it exists. 
  if (app?.url_ref) return app?.url_ref

  //2. If url is empty then return our local web link.
  if (!app?.url) return `/${app?.slug}`  // if no URL return our local URL 

  // 3. If url exists return url with ref ending. 
  // CLASSIC: ?ref=stackreaction
  // SAASHUB: ?utm_source=saashub&utm_medium=marketplace&utm_campaign=saashub  
  const ref = '?ref=stackreaction'  // ?utm_source=stackreaction&utm_medium=marketplace&utm_campaign=stackreaction
  const url = `${app?.url}${ref}` 
  return url
}


export const appPricingLink = (app) => {  
  const priceUrl = app?.app_price?.price_url
  if (!priceUrl) return null 
  // const query = '?utm_source=stackreaction'
  const ref = '?ref=stackreaction'
  const url = `${app?.url}${ref}`
  return url
}


// export const sourceWebsiteLink = (source) => {
  
//   if (source?.url_ref) return url_ref
//   if (source?.url) {
//     const ref = '?ref=stackreaction' // ?utm_source=stackreaction&utm_medium=marketplace&utm_campaign=stackreaction
//     return `${source?.url}${ref}` 
//   }
//   return appWebsiteLink(source?.app) || '#'
// }

export const appShortDescription = (app) => {
  return app?.title || app?.primary_category?.name
}


export const appIsSourceAutomation = (app) => {
  // hard-coded for speed purposes
  const sources = ['zapier', 'ifttt', 'integromat', 'automateio', 
                   'microsoft-flow', 'albato', 'unito', 'integrately', 
                   'syncspider', 'n8n', 'pabbly-connect', 'pipedream', 
                   'make', 'power-automate']
  return sources.includes(app?.slug)
}


export const categoryPageTitle = (category) => {
  // Capterra: Find the best OKR Software for your business. Compare product reviews and features to build your list.
  // SAASHUB: The best Accounting Software based on votes, our collection of reviews, verified products and a total of 25,969 factors
  const title = category?.parent?.id ? `Find the best ${category?.name} Software` : ` ${category?.name} Software Subcategories`  
  return title 
}



export const sanitizeExtActionDescription = (description='') => {
  // Example. Triggers when a new contact is found. **Note:** This trigger requires webhook creation permissions. See [here](https://qadev.autotask.net/help/DeveloperHelp/Content/APIs/Webhooks/WEBHOOKS.htm?cshid=1001) on how to enable for your Zapier integration user.  
  // Radically strim everything after **
  let decrSanitized = description
  try {
    const [descr, rest] = description?.split('**')
    decrSanitized = descr?.trim()
  } catch (err) {
  }
  return decrSanitized
}



export const appConnectionExternalURL = ({ sourceName='', appConnection={} }) => {
  // https://zapier.com/apps/slack/integrations/trello
  // https://www.make.com/en/integrations/google-sheets/dropbox
  // https://automate.io/integration/slack/trello
  // https://ifttt.com/connect/slack/trello
  // https://pipedream.com/apps/trello/integrations/slack
  // https://www.pabbly.com/connect/integrations/trello/activecampaign/
  // https://integrately.com/integrations/slack/trello


  const { app, app_connection } = appConnection
  const slug1 = app?.slug 
  const slug2 = app_connection?.slug 
  if (sourceName === 'zapier') {
    return `https://zapier.com/apps/${slug1}/integrations/${slug2}`
  } else if (sourceName === 'integromat' || sourceName === 'make') {
    return `https://www.make.com/en/integrations/${slug1}/${slug2}`
  } else if (sourceName === 'automateio') {
    return `https://automate.io/integration/${slug1}/${slug2}`
  } else if (sourceName === 'ifttt') {
    return `https://ifttt.com/connect/${slug1}/${slug2}`
  } else if (sourceName === 'pipedream') {
    return `https://pipedream.com/apps/${slug1}/integrations/${slug2}`
  } else if (sourceName === 'pabbly-connect') {
    return `https://www.pabbly.com/connect/integrations/${slug1}/${slug2}/`
  } else if (sourceName === 'integrately') {
    return `https://integrately.com/integrations/${slug1}/${slug2}/`
  }
  return ''
}



// SYNTHETIC AUTOMATIONS
export const getAutomationIdFromSlug = (slug) => {
  // Check if slug contains id (1323-create-trello-card-on...)
  if (!slug || slug === '') return null
  try {
    const idStr = slug?.split("-")?.[0]
    const autID = parseInt(idStr)
    return autID || null
  } catch {
    return null
  }
}


export const syntheticAutomationName = ({ trigger_name, trigger_app_name, action_name, action_app_name, }) => {
  return `${action_name} with ${action_app_name} on ${trigger_name} from ${trigger_app_name}`
}


export const syntheticAutomationSlug = ({ trigger_slug, trigger_app_slug, action_slug, action_app_slug, }) => {
  return `${action_slug}-with-${action_app_slug}-on-${trigger_slug}-from-${trigger_app_slug}`
}


export const automationTemplateLink = ({ 
  automation_id, 
  automation_name, 
  automation_slug, 
  trigger_name,
  trigger_slug,
  trigger_app_slug,
  trigger_app_name,
  action_name, 
  action_slug,
  action_app_slug,
  action_app_name,
}) => {

  const fullPath = (slug) => `${trigger_app_slug}/integrations/${action_app_slug}/${slug}`

  if (automation_id && automation_slug && automation_name) {
    const realSlug = `${automation_id}-${automation_slug}`
    return {
      href: fullPath(realSlug),
      name: automation_name
    }
  }
  
  // const syntheticName = `${action_name} with ${action_app_name} on ${trigger_name} from ${trigger_app_name}`  
  // const syntheticSlug = `${action_slug}-with-${action_app_slug}-on-${trigger_slug}-from-${trigger_app_slug}`
  const syntheticName = syntheticAutomationName({ action_name, action_app_name, trigger_name, trigger_app_name })  
  const syntheticSlug = syntheticAutomationSlug({ trigger_slug, trigger_app_slug, action_slug, action_app_slug })
  const syntheticPath = fullPath(syntheticSlug)
  
  return {
    href: syntheticPath, 
    name: syntheticName, 
  }
}


export const automationFullPath = (automation) => {
  const automationActionTrigger = automation?.automation_actions?.find(x => (x?.app_action?.type === 'trigger'))
  const automationActionAction = automation?.automation_actions?.find(x => (x?.app_action?.type === 'action'))
  const triggerAppSlug = automationActionTrigger?.app_action?.app?.slug
  const actionAppSlug = automationActionAction?.app_action?.app?.slug
  return `/${triggerAppSlug}/integrations/${actionAppSlug}/${automation?.id}-${automation?.slug}`
}

// USER 
// User Full Name 
export const userFullName = (user={}) => {
  if (!user) return null
  const { first_name, last_name, name, email, username } = user  
  if (first_name && last_name) return `${first_name} ${last_name}`
  if (first_name || last_name) return (first_name || last_name)
  if (name) return name
  if (username) return username
  if (email) return email
  return "user"
}



// Translation. 
export const getTranslationVariant = (id, max) => {
  // input - id of the item. Output - number fom 1 to max. 
  // used for different translation variants
  const seed = 9872679798;  // never change it!
  const hash = simpleHash(seed + id) // example: 2446238317
  const num = (hash-1) % max + 1 // makes sure it is from 1 to max.
  return num
}

const simpleHash = (a) => {
    // int -> int hashing function
    // https://stackoverflow.com/questions/7188310/javascript-pseudo-random-sequence-generator
    a = (a+0x7ed55d16) + (a<<12);
    a = (a^0xc761c23c) ^ (a>>19);
    a = (a+0x165667b1) + (a<<5);
    a = (a+0xd3a2646c) ^ (a<<9);
    a = (a+0xfd7046c5) + (a<<3);
    a = (a^0xb55a4f09) ^ (a>>16);
    if( a < 0 ) a = 0xffffffff + a;
    return a;
}