export const wfaPrices = [
  {
      "id": 1,
      "sourceslug": "zapier",
      "tier": "Free",
      "volume": 100,
      "volumeentity": "task",
      "multistep": false,
      "maxsteps": 2,
      "premiumapps": false,
      "premiumappsmax": 0,
      "pricemonthly": 0,
      "priceyearly": 0,
      "pricecalctype": "per_actions"
  },
  {
      "id": 2,
      "sourceslug": "zapier",
      "tier": "Starter",
      "volume": 750,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": 3,
      "pricemonthly": 29.99,
      "priceyearly": 19.99,
      "pricecalctype": "per_actions"
  },
  {
      "id": 3,
      "sourceslug": "zapier",
      "tier": "Starter",
      "volume": 1500,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 58.5,
      "priceyearly": 39,
      "pricecalctype": "per_actions"
  },
  {
      "id": 4,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 2000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 73.5,
      "priceyearly": 49,
      "pricecalctype": "per_actions"
  },
  {
      "id": 5,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 2000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 73.5,
      "priceyearly": 49,
      "pricecalctype": "per_actions"
  },
  {
      "id": 6,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 5000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 133.5,
      "priceyearly": 89,
      "pricecalctype": "per_actions"
  },
  {
      "id": 7,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 10000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 193.5,
      "priceyearly": 129,
      "pricecalctype": "per_actions"
  },
  {
      "id": 8,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 20000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 283.5,
      "priceyearly": 189,
      "pricecalctype": "per_actions"
  },
  {
      "id": 9,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 50000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 433.5,
      "priceyearly": 289,
      "pricecalctype": "per_actions"
  },
  {
      "id": 10,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 100000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 733.5,
      "priceyearly": 489,
      "pricecalctype": "per_actions"
  },
  {
      "id": 11,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 200000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 1149,
      "priceyearly": 769,
      "pricecalctype": "per_actions"
  },
  {
      "id": 12,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 300000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 1599,
      "priceyearly": 1069,
      "pricecalctype": "per_actions"
  },
  {
      "id": 13,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 400000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 1899,
      "priceyearly": 1269,
      "pricecalctype": "per_actions"
  },
  {
      "id": 14,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 500000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 2199,
      "priceyearly": 1499,
      "pricecalctype": "per_actions"
  },
  {
      "id": 15,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 750000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 2999,
      "priceyearly": 1999,
      "pricecalctype": "per_actions"
  },
  {
      "id": 16,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 1000000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 3299,
      "priceyearly": 2199,
      "pricecalctype": "per_actions"
  },
  {
      "id": 17,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 1250000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 3899,
      "priceyearly": 2599,
      "pricecalctype": "per_actions"
  },
  {
      "id": 18,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 1500000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 4499,
      "priceyearly": 2999,
      "pricecalctype": "per_actions"
  },
  {
      "id": 19,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 1750000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 4799,
      "priceyearly": 3199,
      "pricecalctype": "per_actions"
  },
  {
      "id": 20,
      "sourceslug": "zapier",
      "tier": "Professional",
      "volume": 2000000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 5099,
      "priceyearly": 3389,
      "pricecalctype": "per_actions"
  },
  {
      "id": 21,
      "sourceslug": "integromat",
      "tier": "Free",
      "volume": 1000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": false,
      "premiumappsmax": 0,
      "pricemonthly": 0,
      "priceyearly": 0,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 22,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 10000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 10.59,
      "priceyearly": 9,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 23,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 20000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 18.82,
      "priceyearly": 16,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 24,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 40000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 34.12,
      "priceyearly": 29,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 25,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 80000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 64.71,
      "priceyearly": 55,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 26,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 150000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 116.47,
      "priceyearly": 99,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 27,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 300000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 214.31,
      "priceyearly": 182.16,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 28,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 500000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 388.13,
      "priceyearly": 287.41,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 29,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 750000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 486.9,
      "priceyearly": 413.87,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 30,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 1000000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 631.89,
      "priceyearly": 573.11,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 31,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 1500000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 909.92,
      "priceyearly": 773.44,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 32,
      "sourceslug": "integromat",
      "tier": "Core",
      "volume": 2000000,
      "volumeentity": "operation",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 1162.68,
      "priceyearly": 988.28,
      "pricecalctype": "per_actions_and_triggers"
  },
  {
      "id": 33,
      "sourceslug": "albato",
      "tier": "Free",
      "volume": 100,
      "volumeentity": "transaction",
      "multistep": false,
      "maxsteps": 2,
      "premiumapps": false,
      "premiumappsmax": 0,
      "pricemonthly": 0,
      "priceyearly": 0,
      "pricecalctype": "per_actions"
  },
  {
      "id": 34,
      "sourceslug": "albato",
      "tier": "Basic",
      "volume": 1000,
      "volumeentity": "transaction",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 19,
      "priceyearly": 15,
      "pricecalctype": "per_actions"
  },
  {
      "id": 35,
      "sourceslug": "albato",
      "tier": "Standard",
      "volume": 5000,
      "volumeentity": "transaction",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 53,
      "priceyearly": 42,
      "pricecalctype": "per_actions"
  },
  {
      "id": 36,
      "sourceslug": "albato",
      "tier": "Pro",
      "volume": 15000,
      "volumeentity": "transaction",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 130,
      "priceyearly": 104,
      "pricecalctype": "per_actions"
  },
  {
      "id": 37,
      "sourceslug": "albato",
      "tier": "Enterprise",
      "volume": 30000,
      "volumeentity": "transaction",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 202,
      "priceyearly": 162,
      "pricecalctype": "per_actions"
  },
  {
      "id": 38,
      "sourceslug": "pipedream",
      "tier": "Free",
      "volume": 3000,
      "volumeentity": "credit",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 0,
      "priceyearly": 0,
      "pricecalctype": "per_executions"
  },
  {
      "id": 39,
      "sourceslug": "pipedream",
      "tier": "Basic",
      "volume": 10000,
      "volumeentity": "credit",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 29,
      "priceyearly": 19,
      "pricecalctype": "per_executions"
  },
  {
      "id": 40,
      "sourceslug": "pipedream",
      "tier": "Advanced",
      "volume": 50000,
      "volumeentity": "credit",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 149,
      "priceyearly": 99,
      "pricecalctype": "per_executions"
  },
  {
      "id": 41,
      "sourceslug": "pipedream",
      "tier": "Business",
      "volume": 250000,
      "volumeentity": "credit",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 499,
      "priceyearly": 499,
      "pricecalctype": "per_executions"
  },
  {
      "id": 42,
      "sourceslug": "integrately",
      "tier": "Starter",
      "volume": 2000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": 3,
      "pricemonthly": 29.99,
      "priceyearly": 19.99,
      "pricecalctype": "per_actions"
  },
  {
      "id": 43,
      "sourceslug": "integrately",
      "tier": "Professional",
      "volume": 10000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 49,
      "priceyearly": 39,
      "pricecalctype": "per_actions"
  },
  {
      "id": 44,
      "sourceslug": "integrately",
      "tier": "Growth",
      "volume": 30000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 124,
      "priceyearly": 99,
      "pricecalctype": "per_actions"
  },
  {
      "id": 45,
      "sourceslug": "integrately",
      "tier": "Business",
      "volume": 150000,
      "volumeentity": "task",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 299,
      "priceyearly": 239,
      "pricecalctype": "per_actions"
  },
  {
      "id": 46,
      "sourceslug": "n8n",
      "tier": "Starter",
      "volume": 5000,
      "volumeentity": "execution",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 20,
      "priceyearly": 20,
      "pricecalctype": "per_executions"
  },
  {
      "id": 47,
      "sourceslug": "n8n",
      "tier": "Pro",
      "volume": 10000,
      "volumeentity": "execution",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 50,
      "priceyearly": 50,
      "pricecalctype": "per_executions"
  },
  {
      "id": 48,
      "sourceslug": "n8n",
      "tier": "Pro",
      "volume": 60000,
      "volumeentity": "execution",
      "multistep": true,
      "maxsteps": "∞",
      "premiumapps": true,
      "premiumappsmax": "∞",
      "pricemonthly": 120,
      "priceyearly": 120,
      "pricecalctype": "per_executions"
  }
]