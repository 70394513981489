// TODO - Move here all constants (SEO, img. etc)

export const websiteName = 'Stackreaction'; 
export const websiteTitle = 'Stackreaction - Build and automate your business without code'; 
export const websiteDescription = 'Explore nocode tools, integrations, stacks and tutorials'; 

export const websiteUrl = 'https://stackreaction.com'
export const websiteImageBaseUrl = 'https://img.stackreaction.com'

export const defaultAuthorName = "Alex Tro"  // When used in articles. 
export const defaultAuthorUrl = `${websiteUrl}/users/alextro`  // When used in articles. 
export const defaultPublisherName = "StackReaction"  // When used in articles. 

export const WEBSITE_INTEGRATIONS_PAGE_IMAGE = {
  url: `${websiteImageBaseUrl}/website/integrations-page-og.png`,
  width: '1200',
  height: '630',
  alt: 'Workflow Automation Templates and App Integrations'
}


export const landingHeroImage = {
  src: `${websiteImageBaseUrl}/landing/landing-hero-image.jpg`,
  alt: 'Stackreaction - nocode community',
  width: '1280',
  height: '854'
}  // used on landing page and seo og:image